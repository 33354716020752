import React, { useState, useEffect } from "react";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import ReactQuill from "react-quill";

const PrivacyEdit = () => {
  const [text, setText] = useState("");

  // Fetch the existing FAQ content on component mount
  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const response = await axiosInstance.get('/faq', config);
        // console.log(response?.data?.data?.[0].details|| "")
        setText(response?.data?.data?.[0].details || "");
      } catch (error) {
        toast.error("Failed to fetch FAQ content.");
      }
    };
    fetchFAQ();
  }, []);

  // Handle change in the editor
  const handleChange = (value) => {
    setText(value);
  };

  // Handle submit for updating FAQ content
  const handleSubmit = async () => {
    try {
      await axiosInstance.post('/faq', { details : text }, config);
      toast.success("FAQ content updated successfully!");
    } catch (error) {
      toast.error("Failed to update FAQ content.");
    }
  };

  const editorStyle = {
    height: "400px",
    backgroundColor: "white",
  };

  return (
    <div>
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          <h2 className="headline">Edit Privacy Policy</h2>
          <div className="main-content-child">
            <div className="mb-3">
              <ReactQuill
                theme="snow"
                value={text}
                onChange={handleChange}
                style={editorStyle}
              />
            </div>
          </div>
            <button onClick={handleSubmit} className="btn btn-primary mt-3 ms-3">
              Save Changes
            </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyEdit;
