import React, { useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";

const AboutUsEdit = () => {
  const [aboutUs, setAboutUs] = useState({
    title: "",
    description: "",
    featured_jobs: "",
    psu_jobs: "",
    career_site: "",
    third_party_listings: "",
    sonia_description: "",
    about_us_image: "",
    sonia_image: "",
    illustrate_image: "",
    team: [],
  });
 let header = config
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await axiosInstance.get("about-us");
        setAboutUs(response?.data?.about);
      } catch (err) {
        setError("Failed to fetch About Us data");
      } finally {
        setLoading(false);
      }
    };
    fetchAboutUs();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutUs((prevAboutUs) => ({
      ...prevAboutUs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("about-us", aboutUs ,header);
      toast(response.data.message);
    } catch (err) {
      setError("Failed to update About Us data");
    }
  };

  const handleImageUpload = async (e, type) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
     // Retrieve the token from localStorage
     const token = JSON.parse(localStorage.getItem("token"));
    try {
      const response = await axiosInstance.post(`/about-us`, formData, {
        headers: { "Content-Type": "multipart/form-data" ,  "Authorization": `Bearer ${token}`  },
      });
      setAboutUs((prevAboutUs) => ({
        ...prevAboutUs,
        [type]: response?.data?.imageUrl,
      }));
      toast.success("Image uploaded successfully!");
    } catch (err) {
      setError(`Failed to upload ${type} image`);
    }
  };

  return (
    <>
      <div>
        <AdminNavbar />
        <div className="row m-0">
          <div className="col-md-2 p-0 nav-content">
            <AdminSideBar />
          </div>
          <div className="main-content col-md-10 p-0">
            <h2 className="headline">Edit Contact Us</h2>
            <div className="main-content-child col-md-10">
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">About Description</label>
                    <input
                      type="text"
                      name="title"
                      value={aboutUs.title}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Featured Jobs Description</label>
                    <textarea
                      name="featured_jobs"
                      value={aboutUs.featured_jobs}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">PSU Jobs Description</label>
                    <textarea
                      name="psu_jobs"
                      value={aboutUs.psu_jobs}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Career Site Description</label>
                    <textarea
                      name="career_site"
                      value={aboutUs.career_site}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Third Party Listings Description</label>
                    <textarea
                      name="third_party_listings"
                      value={aboutUs.third_party_listings}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Site Description</label>
                    <textarea
                      name="description"
                      value={aboutUs.description}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Sonia's Description</label>
                    <textarea
                      name="sonia_description"
                      value={aboutUs.sonia_description}
                      onChange={handleChange}
                      className="form-control"
                      rows="4"
                    />
                  </div>

                  <button type="submit" className="btn btn-primary mt-2">
                    Update About Us
                  </button>

                  {/* Image Upload Sections */}
                  <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">About Us Image</label>
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e, "about_us_image")}
                      className="form-control"
                    />
                    {aboutUs.about_us_image && <img src={aboutUs.about_us_image} alt="About Us" width="100" />}
                  </div>

                  {/* <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Sonia Image</label>
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e, "sonia_image")}
                      className="form-control"
                    />
                    {aboutUs.sonia_image && <img src={aboutUs.sonia_image} alt="Sonia" width="100" />}
                  </div> */}

                  {/* <div className="form-group mt-2">
                    <label className="mb-2 fw-bold">Illustrate Image</label>
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e, "illustrate")}
                      className="form-control"
                    />
                    {aboutUs.illustrate_image && <img src={aboutUs.illustrate_image} alt="Illustrate" width="100" />}
                  </div> */}

                  {/* Team Image Upload Section */}
                  {/* {aboutUs.team.map((member, index) => (
                    <div key={index} className="form-group mt-2">
                      <label className="mb-2 fw-bold">Team Member Image ({member.name})</label>
                      <input
                        type="file"
                        onChange={(e) => handleImageUpload(e, `team-${index}`)}
                        className="form-control"
                      />
                      {member.image && <img src={member.image} alt="Team Member" width="100" />}
                    </div>
                  ))} */}

                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsEdit;
