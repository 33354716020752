import React from 'react';
import Img1 from "../../asset/new/images/1.jpg";
import Img2 from "../../asset/new/images/2.jpg";
import Img3 from "../../asset/new/images/3.jpg";
import Img4 from "../../asset/new/images/4.png";
import Img5 from "../../asset/new/images/5.png";
import Img6 from "../../asset/new/images/6.jpg";
import Img7 from "../../asset/new/images/7.png";
import Img8 from "../../asset/new/images/8.png";

const Milestone = () => {
  const data = [
    { image: Img1, desc: "How this husband-wife duo built a recruitment platform exclusively for CAs" },
    { image: Img2, desc: "A job site for CAs by CAs" },
    { image: Img3, desc: "New online start-ups bring employers, jobseekers closer" },
    { image: Img4, desc: "2 IIM-A graduates take altruistic risk" },
    { image: Img5, desc: "IIMA grad to help CA moms & retired people get jobs" },
    { image: Img6, desc: "The Journey: Cajobportal.com" },
    { image: Img7, desc: "Meet Sonia, a stay-at-home working mom clocking company revenues in millions!" },
    { image: Img8, desc: "This Indian couple has built a platform exclusively for Finance Professionals" },
  ];

  return (
    <>
      {/* Hero Section */}
      <div>
        <div className="row hero-job">
          <h1 className="fs-3 text-center p-3">Milestones</h1>
        </div>
      </div>
      <div className='w-75 ms-4' style={{padding: '40px', paddingLeft:"60px"}}>
      <p>In CY 16, we have secured traffic of ~950,000 page views thru ~100,000 users It is indeed a matter of great pride, when even CFOs of listed companies have begun to trust cajobportal.com for discussing their next career moves.</p>
      <p>Our efforts have also been covered by prestigious media houses.</p>
      <div>
        {data?.map((item, index) => (
          <div className='mb-3' key={index}>
            <img src={item?.image} className='img-fluid' alt={item?.desc}  style={{ height: '200px', width: '40%' }}  />
            <span className='ms-3'>{item?.desc}</span>
          </div>
        ))}
      </div>
      </div>

    </>
  );
};

export default Milestone;
